.gcs-list {
	.calendar-dot {
		height: 10px;
		width: 10px;
        min-height: 10px;
        min-width: 10px;
		border-radius: 50%;
	}
	.row-title {
		display: flex;
		align-items: center;
        max-width: 300px;
	}
}
