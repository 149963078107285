.resource-tile {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	position: relative;

	.drag-icon {
		display: none;
		cursor: grab;
	}

	&:hover {
		.drag-icon {
			display: block;
		}

		.tile-icon {
			display: none;
		}
	}
}
