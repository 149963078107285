.currentTimeBar {
  border-top: 2px solid #ea4335;
  position: relative;
  z-index: 500;
  &::before {
    content: '';
    background-color: #ea4335;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: 500;
    pointer-events: none;
  }
}
