.pintura-editor {
  @include desktop() {
    --editor-max-width: 80%;
    --editor-max-height: 80%;
    --editor-modal-overlay-opacity: 0.7;
  }
}

.PinturaStage {
  cursor: grab;
}
