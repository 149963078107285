.header {
    display: flex;
    flex: 1;
  
    > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
  
      > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  
    > div:first-child > div {
      margin-right: auto;
    }
  
    > div:last-child > div {
      margin-left: auto;
    }
  }