@mixin mobile() {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 768px) {
    @content;
  }
}

.hide-scroll-bar {
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;

  ::-webkit-scrollbar {
    display: none !important;
    background-color: transparent;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.square {
  position: relative;
  width: 50%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

// Stolen from https://css-tricks.com/snippets/css/fluid-typography/
// Commented out because it causes the React app not to build on Amazon CodeBuild...
// @function strip-unit($value) {
//   @return $value / ($value * 0 + 1);
// }

// @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
//   $u1: unit($min-vw);
//   $u2: unit($max-vw);
//   $u3: unit($min-font-size);
//   $u4: unit($max-font-size);

//   @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
//     & {
//       font-size: $min-font-size;
//       @media screen and (min-width: $min-vw) {
//         font-size: calc(
//           #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
//             ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
//         );
//       }
//       @media screen and (min-width: $max-vw) {
//         font-size: $max-font-size;
//       }
//     }
//   }
// }

.resource-tile-label {
  @include mobile() {
    font-size: 16px;
    // @include fluid-type(320px, 1200px, 16px, 24px);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  @include desktop() {
    font-size: large;
  }
}

// TODO: doesn't seem to work...
.print-prevent-break {
  @media print {
    position: relative;
    break-inside: avoid;
  }
}
