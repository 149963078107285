.embed-display {
	@include desktop() {
		&:not(.mobile-preview) {
			&.youtube {
				height: 422px;
			}

			&.vimeo {
				height: 300px;
			}
		}
	}

	&.apple-podcast {
		height: 450px;
	}

	&.spotify {
		height: 80px;
	}

	&.soundcloud {
		height: 115px;
	}
}
