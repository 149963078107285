.compactInput {
  input {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  button {
    padding: 0px 4px;
  }

  // Remove the date icon since the library doesn't have any controls for that 🙄
  // > div > div {
  //   display: none;
  // }
}

.tox.tox-tinymce {
  flex: 1;
}

.filepond--file-action-button {
  cursor: pointer !important;
}

.filepond--credits {
  display: none;
}

.filepond--drop-label {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &:hover {
    // border-width: 1px;
    // border-color: gray;
    // border-style: solid;
    // border-radius: 10px;
    //   -webkit-box-shadow: 0 8px 6px -6px black;
    //   -moz-box-shadow: 0 8px 6px -6px black;
    //   box-shadow: 0 8px 6px -6px black;
  }
}
