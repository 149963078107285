.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.line-clamp {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;

	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;

	&.one-line {
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
	}

	&.three-lines {
		-webkit-line-clamp: 3; /* number of lines to show */
		line-clamp: 3;
	}
}
