.triangle-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 20px solid;
  border-bottom: 10px solid transparent;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid black;
}

.fading-divider {
  height: 1px;
  background: #616161;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(white),
    color-stop(50%, #616161)
  );
}
