.sortable-target {
  &.invalid {
    border: 2px solid red !important;
  }
}

.page-section-drag-ghost {
  background-color: red;
  border: 10px solid red !important;
}
